<template>
  <div>
    <vs-popup class="sm:popup-w-30 popup-content-no-padding" title="Input Amount" :active="isActive" v-on:update:active="emitModalIsActive">
      <div class="h-screen-80 p-2">
        <div class="w-full h-full flex flex-col space-y-3">
          <!--amount-->
          <div class="flex-none border-2 border-solid d-theme-border-grey-light d-theme-dark-bg">
            <div class="flex h-16 items-center justify-end">
              <input ref="inputAmount" @focus="$event.target.select()" type="text" :value="data.amountFormatted" class="w-full h-full border-none p-2 text-4xl text-right font-bold" readonly/>
            </div>
          </div>

          <!--remark-->
          <div class="flex-none">
            <div class="flex space-x-2 items-center">
              <label class="w-32 p-1">Remark</label>
              <vs-input class="w-full" placeholder="Type Remark" v-model="data.remark"/>
            </div>
          </div>

          <!--numpad-->
          <div class="flex-1 flex flex flex-col w-full h-full">
            <div class="flex-1 flex flex-row">
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('7')">7</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('8')">8</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('9')">9</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-xl font-bold text-center p-0" @click="backSpace">Back Space</div>
            </div>
            <div class="flex-1 flex flex-row">
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('4')">4</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('5')">5</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('6')">6</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-xl font-bold text-center p-0" @click="clear">Clear</div>
            </div>
            <div class="flex-1 flex flex-row">
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('1')">1</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('2')">2</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('3')">3</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-xl font-bold text-center p-0">-</div>
            </div>
            <div class="flex-1 flex flex-row">
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('0')">0</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('00')">00</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-4xl font-semibold text-center p-4" @click="addString('000')">000</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-xl font-bold text-center p-0" @click="addString('.')">.</div>
            </div>
            <div class="flex-1 flex flex-row">
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-2xl font-bold text-center p-4" @click="cancel">CANCEL</div>
              <div class="flex-1 flex items-center justify-center border border-solid d-theme-border-grey-light d-theme-dark-bg hover-bg-primary-transparent-5 cursor-pointer select-none text-2xl font-bold text-center p-0" @click="ok">OK</div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'PayAmount',
  props: {
    isActive: { required: true, type: Boolean },
    balance: { required: false, type: Number }
  },
  watch: {
    isActive (active) {
      if (active) {
        if (this.balance) {
          const string = Math.ceil(this.balance).toString()
          this.addString(string)
          this.$nextTick(() => {
            this.$refs.inputAmount.focus()
            this.data.isAllTextSelected = true
          })
        }
      }
    }
  },
  computed: {
    amount () {
      const amountString = this.data.amountString
      if (amountString === '') return 0
      return parseFloat(amountString)
    }
  },
  data () {
    return {
      data: {
        isAllTextSelected: false,
        amountString: '',
        amountFormatted: '',
        remark: null
      }
    }
  },
  methods: {
    addString (string) {
      const charLengthAfterDecimalPoint = (this.data.amountString.split('.')[1] || '').length
      if (charLengthAfterDecimalPoint < 2) {
        if (string === '.') {
          if (this.data.amountString !== '') {
            const isAmountStringAlreadyContainDecimalDot = this.data.amountString.includes('.')
            if (!isAmountStringAlreadyContainDecimalDot) {
              this.data.amountString += string
            }
          }
        } else {
          if (this.data.isAllTextSelected) {
            this.data.amountString = string
          } else {
            this.data.amountString += string
          }
        }
        this.data.isAllTextSelected = false
      }
      this.formatAmount()
    },

    formatAmount () {
      const amountStringLastDotRemoved = this.data.amountString.replace(/\.$/, '')
      const isContainLastDot = this.data.amountString.charAt(this.data.amountString.length - 1) === '.'
      this.data.amountFormatted = this.currencyFormat(amountStringLastDotRemoved) + (isContainLastDot ? '.' : '')
    },

    currencyFormat (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    backSpace () {
      if (this.data.amountString !== '') {
        const amountString = this.data.amountString
        this.data.amountString = amountString.substring(0, amountString.length - 1)
      }
      this.formatAmount()
    },

    clear () {
      this.data.amountString = ''
      this.data.amountFormatted = ''
    },

    cancel () {
      this.emitModalIsActive(false)
    },

    ok () {
      if (this.amount > 0) {
        this.$emit('ok', {
          amount: this.amount,
          remark: this.data.remark
        })
        this.emitModalIsActive(false)
      }
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
      this.resetData()
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    }
  }
}
</script>

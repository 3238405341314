<template>
  <div>
    <vs-popup class="sm:popup-w-40" title="Guest Info" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Nama *</label>
            <vs-input class="w-full" placeholder="Nama" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">HP</label>
            <vs-input class="w-full" type="tel" placeholder="HP" v-model="data.hp"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Email</label>
            <vs-input class="w-full" placeholder="Email" v-model="data.email"/>
          </div>
        </div>
        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button color="success" @click="emitModalIsActive(false)">Submit</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'GuestInfoForm',
  props: ['isActive'],
  data () {
    return {
      data: {
        nama: null,
        hp: null,
        email: null
      }
    }
  },
  methods: {
    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
      if (!isActive) {
        this.emitSubmitted()
      }
    },

    emitSubmitted () {
      this.$emit('done', _.cloneDeep(this.data))
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    }
  }
}
</script>

<template>
  <div>
    <vs-popup class="sm:popup-w-80 popup-content-no-padding" title="Payment" :active="isActive" v-on:update:active="emitModalIsActive">
      <div class="h-screen-80">
        <div class="w-full h-full md:flex">
          <div class="flex-1 border border-solid d-theme-border-grey-light overflow-y-scroll">
            <div class="grid grid-cols-3">
              <div v-for="item in caraBayars" :key="item.id" @click="showModalPayAmount(item)" class="h-32 cursor-pointer hover-bg-primary-transparent-10 border border-t-0 border-solid d-theme-border-grey-light">
                <div class="w-full h-full p-2 flex items-center justify-center">
                  <span class="text-center font-bold text-lg">{{ item.nama }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1 flex flex-col border border-solid d-theme-border-grey-light">
            <!--summary-->
            <div class="flex-none border-solid d-theme-border-grey-light p-2">
              <div class="flex space-x-2 items-center">
                <label class="text-sm w-32">Guest Name *</label>
                <vs-input class="w-full" placeholder="Guest Name" v-model="order.nama"/>
              </div>
              <div class="flex space-x-2 items-center mt-1">
                <label class="text-sm w-32">Phone</label>
                <vs-input class="w-full" placeholder="Phone" v-model="order.hp"/>
              </div>
              <div class="flex space-x-2 items-center mt-1">
                <label class="text-sm w-32">Email</label>
                <vs-input class="w-full" placeholder="Email" v-model="order.email"/>
              </div>
              <vs-divider></vs-divider>
              <div class="flex space-x-2 items-center">
                <label class="text-sm w-32">Grand Total</label>
                <v-money class="w-full" :value="grandTotal" theme="theme4" readonly/>
              </div>
              <div class="flex space-x-2 items-center mt-1">
                <label class="text-sm w-32">Total Paid</label>
                <v-money class="w-full text-success" :value="totalPaid" theme="theme4" readonly/>
              </div>
              <div class="flex space-x-2 items-center mt-1">
                <label class="text-sm w-32">Balance</label>
                <v-money class="w-full" :value="totalBalance" theme="theme4" readonly/>
              </div>
              <div class="flex space-x-2 items-center mt-1">
                <label class="text-sm w-32">Change</label>
                <v-money class="w-full" :value="totalChange" theme="theme4" readonly/>
              </div>
            </div>
            <!--selected payment list-->
            <div class="flex-1 flex flex-col border border-solid d-theme-border-grey-light">
              <div class="flex-none flex items-center h-8 px-2 space-x-2 border border-t-0 border-solid d-theme-border-grey-light">
                <span class="text-sm">Payments</span>
              </div>
              <div class="flex-1">
                <table class="table-auto sm:table-fixed border-collapse w-full d-theme-dark-bg">
                  <thead>
                    <tr>
                      <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-sm w-5">#</th>
                      <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-sm w-12">Action</th>
                      <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-sm w-32">Payment Method</th>
                      <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-sm w-32">Remark</th>
                      <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-sm w-32">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in paymentMethods" :key="index">
                      <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-sm">{{ index + 1 }}</td>
                      <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-sm text-center"><feather-icon icon="XIcon" svgClasses="h-5 w-5" class="text-danger cursor-pointer" @click="removePayment(item.uuid)"/></td>
                      <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-sm">{{ item.nama_cara_bayar }}</td>
                      <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-sm">{{ item.keterangan }}</td>
                      <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-sm text-right">{{ item.jml_bayar | idr }}</td>
                    </tr>
                  </tbody>
                  <tfoot v-if="paymentMethods.length > 0">
                    <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                      <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-right"></th>
                      <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-right"></th>
                      <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-right"></th>
                      <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-right"></th>
                      <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-right">{{ totalPaid | idr }}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <!--button-->
            <div class="flex-none h-16 flex border border-solid d-theme-border-grey-light">
              <div @click="cancel" class="flex-1 flex items-center justify-center cursor-pointer bg-danger">
                <span class="text-white font-bold text-lg">CANCEL</span>
              </div>
              <div @click="pay" class="flex-1 flex items-center justify-center cursor-pointer bg-success" :class="{'bg-success opacity-50 pointer-events-none': (!isEnoughPayment || isSaving)}">
                <span class="text-white font-bold text-lg">{{ isSaving ? 'Saving..' : 'PAY' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--modals-->
      <PayAmount :isActive.sync="modalPayAmount.active"
                 :balance="totalBalance"
                 @ok="onPayAmountSubmitted"/>
    </vs-popup>
  </div>
</template>

<script>
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import VMoney from '@/views/components/v-money/VMoney'
import PayAmount from '@/views/pages/estate/pos/station-order/PayAmount'
import PosRepository from '@/repositories/estate/pos-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'ProcessPayment',
  props: {
    isActive: { required: true, type: Boolean },
    order: { required: true, type: Object }
  },
  components: {
    VMoney,
    PayAmount
  },
  mounted () {
    this.getCaraBayar()
  },
  data () {
    return {
      modalPayAmount: {
        active: false,
        paymentMethod: {}
      },
      isSaving: false,
      caraBayars: [],
      paymentMethods: []
    }
  },
  computed: {
    grandTotal () {
      return this.order.grand_total || 0
    },
    totalBalance () {
      const grandTotal = this.grandTotal
      const totalPaid = this.totalPaid
      const balance = grandTotal - totalPaid
      return balance > 0 ? balance : 0
    },
    totalPaid () {
      return _.sumBy(this.paymentMethods, item => item.jml_bayar)
    },
    totalChange () {
      return _.sumBy(this.paymentMethods, item => item.change)
    },
    isEnoughPayment () {
      return this.totalBalance <= 0
    }
  },
  methods: {
    cancel () {
      this.emitModalIsActive(false)
    },

    pay () {
      this.saveOrder()
    },

    saveOrder () {
      this.isSaving = true

      const params = { ...this.order, payments: this.paymentMethods }
      PosRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.notifyErrorUsingDialog(error.response.data.errors)
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    removePayment (uuid) {
      const index = _.findIndex(this.paymentMethods, item => item.uuid === uuid)
      this.paymentMethods.splice(index, 1)
    },

    showModalPayAmount (paymentMethod) {
      if (this.totalBalance > 0) {
        this.modalPayAmount.paymentMethod = paymentMethod
        this.modalPayAmount.active = true
      } else {
        this.notifyWarning('Pembayaran sudah cukup.')
      }
    },

    onPayAmountSubmitted (data) {
      const newBalance = this.totalBalance - data.amount
      const paymentMethod = {
        uuid: uuid(),
        id_cara_bayar: this.modalPayAmount.paymentMethod.id,
        kode_cara_bayar: this.modalPayAmount.paymentMethod.kode,
        nama_cara_bayar: this.modalPayAmount.paymentMethod.nama,
        jml_bayar: data.amount,
        change: newBalance < 0 ? Math.abs(newBalance) : 0,
        keterangan: data.remark
      }
      this.paymentMethods.push(paymentMethod)
    },

    getCaraBayar () {
      const params = {}
      params.filter = JSON.stringify({ group1: 'SALES' })

      CaraBayarRepository.getAll(params)
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onSuccess () {
      this.emitSuccess()
    },

    emitSuccess () {
      this.$emit('success')
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['caraBayars'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    }
  }
}
</script>

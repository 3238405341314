<template>
  <div>
    <vs-popup title="Penjualan Untuk" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Tgl Penjualan *</label>
            <flat-pickr class="w-full" v-model="data.tgl_penjualan"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <vs-select class="w-full" v-model="data.id_proyek">
              <vs-select-item class="w-full" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="it.nama"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button color="success" @click="emitModalIsActive(false)">Submit</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'ProyekChooser',
  props: ['isActive'],
  components: {
    flatPickr
  },
  mounted () {
    this.getAllProyek()
  },
  data () {
    return {
      errors: null,
      data: {
        id_proyek: null,
        tgl_penjualan: moment().format('YYYY-MM-DD')
      },
      proyeks: []
    }
  },
  methods: {
    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
      ProyekRepository.getAll()
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
      if (!isActive) {
        this.emitSelected()
      }
    },

    emitSelected () {
      const proyek = _.find(this.proyeks, item => item.id === this.data.id_proyek)
      this.$emit('selected', {
        id_proyek: this.data.id_proyek,
        kode_proyek: proyek ? proyek.kode : null,
        nama_proyek: proyek ? proyek.nama : null,
        tgl_penjualan: this.data.tgl_penjualan
      })
    }
  }
}
</script>

<template>
  <div class="h-screen">
    <div class="flex flex-col-reverse min-h-screen lg:flex-row">

      <!-- LEFT SECTION -->
      <div class="w-full min-h-screen lg:w-2/5 border border-solid d-theme-border-grey-light">
        <div class="h-screen flex flex-col">
          <!-- header -->
          <div class="flex-none d-theme-dark-bg px-3 h-16">
            <div class="h-full flex flex-row items-center justify-between">
              <div class="font-bold text-xl">Current Order</div>
              <div>{{ basket.items.length }} Items</div>
              <div class="font-semibold">
                <span v-if="basket.items.length > 0" class="rounded-md text-danger cursor-pointer" @click="confirmClearBasket">Clear All</span>
              </div>
            </div>
          </div>
          <!-- basket items -->
          <div class="flex-1 overflow-y-auto border border-l-0 border-r-0 border-solid d-theme-border-grey-light">
            <div v-for="(item, index) in basket.items" :key="index" :ref="item.uuid" class="flex flex-row justify-between items-center p-2 border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
              <div class="flex-1 flex flex-col">
                <div class="ml-2 font-semibold text-sm">{{ item.name }}</div>
                <div class="ml-2 font-light text-xs">{{ item.price | idr }}</div>
              </div>
              <div class="flex-none flex items-center">
                <div class="flex items-center justify-between">
                  <div class="border border-solid d-theme-border-grey-light rounded-full hover-bg-danger-transparent-25" @click="decrementQty(item.uuid)">
                    <feather-icon icon="MinusIcon" class="cursor-pointer text-danger p-3" svg-classes="h-3 w-3"></feather-icon>
                  </div>
                  <span class="w-5 text-center font-semibold text-sm mx-3">{{ item.qty }}</span>
                  <div class="border border-solid d-theme-border-grey-light rounded-full hover-bg-success-transparent-25" @click="incrementQty(item.uuid)">
                    <feather-icon icon="PlusIcon" class="cursor-pointer text-success p-3" svg-classes="h-3 w-3"></feather-icon>
                  </div>
                </div>
                <div class="w-32 font-medium text-sm text-right">
                  <span class="mr-2">{{ item.price * item.qty | idr }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- footer -->
          <div class="flex-none">
            <div class="border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
              <div class="px-4 py-4 d-theme-dark-bg">
                <div class="border-t-2 py-2 flex items-center justify-between">
                  <span class="font-semibold text-xl">Grand Total</span>
                  <span class="font-bold text-xl">{{  grandTotal | idr }}</span>
                </div>
              </div>
            </div>
            <div>
              <div class="px-4 py-4 d-theme-dark-bg cursor-pointer" @click="modalGuestInfo.active = true">
                <div class="flex flex-row justify-between items-center">
                  <div class="flex flex-col">
                    <span class="text-xs font-semibold opacity-75">Guest Info</span>
                    <span class="text-xl font-bold uppercase truncate">{{ guestInfo.nama || 'Guest' }}</span>
                    <div class="flex items-center space-x-2">
                      <span class="text-xs">phone: {{ guestInfo.hp || '-' }}</span>
                      <span class="text-xs">|</span>
                      <span class="text-xs">email: {{ guestInfo.email || '-' }}</span>
                    </div>
                  </div>
                  <div>
                    <feather-icon icon="ChevronRightIcon" svg-classes="h-5 w-5"></feather-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 flex">
              <vs-button class="flex-1 rounded-none" color="danger" :disabled="basket.items.length < 1" @click="confirmCancel">CANCEL</vs-button>
              <vs-button class="flex-1 rounded-none" color="success" :disabled="basket.items.length < 1" @click="showModalPayment">PAY</vs-button>
            </div>
          </div>
        </div>
      </div>

      <!-- RIGHT SECTION -->
      <div class="w-full min-h-screen lg:w-3/5 border border-solid d-theme-border-grey-light">
        <div class="flex flex-col h-screen">
          <!-- header -->
          <div class="flex-none d-theme-dark-bg h-16 flex justify-between items-center px-5 py-12 sm:py-0 border border-solid border-t-0 d-theme-border-grey-light">
            <div class="flex items-center space-x-3">
              <div class="font-bold text-lg">Item Sales</div>
            </div>
            <div class="flex flex-wrap items-center space-x-3 sm:space-x-6">
              <div class="text-xs md:text-sm flex flex-col items-center">
                <div class="font-light mb-1">Change Price</div>
                <vs-checkbox size="small" v-model="isModeChangePriceActive"/>
              </div>
              <div class="text-xs md:text-sm">
                <div class="font-light">Tgl Penjualan</div>
                <div class="font-semibold underline cursor-pointer" @click="modalProyekChooser.active = true">{{ dateName }}</div>
              </div>
              <div class="text-xs md:text-sm">
                <div class="font-light">Proyek</div>
                <div class="font-semibold underline cursor-pointer" @click="modalProyekChooser.active = true">{{ data.kode_proyek || 'Pilih' }}</div>
              </div>
              <div class="text-xs md:text-sm">
                <div class="font-light">User</div>
                <div class="font-semibold">{{ user.username }}</div>
              </div>
            </div>
          </div>
          <!-- search -->
          <div class="flex-none w-full">
            <vs-input v-model="search" icon-no-border size="large" icon-pack="feather" icon="icon-search" placeholder="Cari Item Sales" class="vs-input-no-border vs-input-no-shdow-focus rounded-none w-full"/>
          </div>
          <!-- loading -->
          <vs-progress indeterminate color="primary" :height="1" :class="{'invisible': !isLoadingGetItemSales}"/>
          <!-- item sales -->
          <div class="grid grid-cols-4 gap-0 overflow-y-auto">
            <div v-for="(item, index) in itemSalesFiltered" :key="index" @click="onClickItemSales(item)" class="p-2 border border-solid d-theme-border-grey-light items-center text-center cursor-pointer select-none h-32 hover-bg-primary-transparent-25">
              <div class="h-full w-full flex items-center">
                <span class="font-extrabold w-full text-center">{{ item.nama }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modals -->
    <ProyekChooser :isActive.sync="modalProyekChooser.active" @selected="onProyekSelected"/>
    <GuestInfoForm ref="guestInfoForm" :isActive.sync="modalGuestInfo.active" @done="onGuestInfoSubmitted"/>
    <ProcessPayment ref="processPayment" :isActive.sync="modalProcessPayment.active" :order="modalProcessPayment.order" @success="onSuccess"/>
    <ChangePrice :isActive.sync="modalChangePrice.active" :balance="modalChangePrice.item.harga" @ok="onPriceChanged"/>
  </div>
</template>

<script>
import ProyekChooser from '@/views/pages/estate/pos/station-order/ProyekChooser'
import GuestInfoForm from '@/views/pages/estate/pos/station-order/GuestInfoForm'
import ProcessPayment from '@/views/pages/estate/pos/station-order/ProcessPayment'
import ItemSalesRepository from '@/repositories/master/item-sales-repository'
import ChangePrice from '@/views/pages/estate/pos/station-order/ChangePrice'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'StationOrder',
  components: {
    ChangePrice,
    ProyekChooser,
    GuestInfoForm,
    ProcessPayment
  },
  mounted () {
    this.modalProyekChooser.active = true
    this.getItemSales()
  },
  computed: {
    dateName () {
      const tglPenjualan = this.data.tgl_penjualan
      if (!tglPenjualan) return '-'
      return moment(tglPenjualan).format('D MMM YYYY')
    },
    user () {
      return this.$store.state.auth.user
    },
    grandTotal () {
      return _.sumBy(this.basket.items, item => (item.price * item.qty))
    },
    itemSalesFiltered () {
      const items = this.itemSales
      const search = this.search
      if (search) {
        return _.filter(items, (item) => {
          return item.nama.toLowerCase().includes(search)
        })
      }
      return items
    }
  },
  data () {
    return {
      search: null,
      isLoading: false,
      isLoadingGetItemSales: false,
      isModeChangePriceActive: true,
      itemSales: [],
      modalProyekChooser: {
        active: false
      },
      modalGuestInfo: {
        active: false
      },
      modalProcessPayment: {
        order: {},
        active: false
      },
      modalChangePrice: {
        active: false,
        item: {}
      },
      data: {
        id_proyek: null,
        kode_proyek: null,
        nama_proyek: null,
        tgl_penjualan: null
      },
      guestInfo: {},
      paymentInfo: {},
      basket: {
        items: []
      }
    }
  },
  methods: {
    getItemSales () {
      this.isLoadingGetItemSales = true

      ItemSalesRepository.getAll()
        .then(response => {
          this.itemSales = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan')
        })
        .finally(() => {
          this.isLoadingGetItemSales = false
        })
    },

    onSuccess () {
      this.notifySuccess('Order berhasil disimpan.')
      this.resetDataGuestInfoForm()
      this.resetProcessPayment()
      this.resetData()
    },

    onProyekSelected (data) {
      this.data.id_proyek = data.id_proyek
      this.data.kode_proyek = data.kode_proyek
      this.data.nama_proyek = data.nama_proyek
      this.data.tgl_penjualan = data.tgl_penjualan
    },

    showModalPayment () {
      this.modalProcessPayment.order = this.buildOrderData()
      this.modalProcessPayment.active = true
    },

    buildOrderData () {
      return {
        id_proyek: this.data.id_proyek,
        tgl_penjualan: this.data.tgl_penjualan,
        nama: this.guestInfo.nama,
        hp: this.guestInfo.hp,
        email: this.guestInfo.email,
        keterangan: null,
        grand_total: this.grandTotal,
        details: _.map(this.basket.items, item => {
          return {
            id_item_sales: item.id_item_sales,
            qty: item.qty,
            harga: item.price,
            keterangan: null
          }
        })
      }
    },

    onClickItemSales (item) {
      if (this.isModeChangePriceActive) {
        this.showModalChangePrice(item)
      } else {
        this.addToBasket(item)
      }
    },

    addToBasket (item) {
      const basketItem = {
        uuid: uuid(),
        id_item_sales: item.id,
        name: item.nama,
        qty: 1,
        price: item.harga
      }
      this.basket.items.push(basketItem)
      this.$nextTick(() => this.scrollToElement(basketItem.uuid))
    },

    incrementQty (uuid) {
      const index = _.findIndex(this.basket.items, item => item.uuid === uuid)
      this.basket.items[index].qty++
    },

    decrementQty (uuid) {
      const index = _.findIndex(this.basket.items, item => item.uuid === uuid)
      const item = this.basket.items[index]
      if (item.qty <= 1) {
        this.removeBasketItem(uuid)
      } else {
        this.basket.items[index].qty--
      }
    },

    removeBasketItem (uuid) {
      const index = _.findIndex(this.basket.items, item => item.uuid === uuid)
      this.basket.items.splice(index, 1)
    },

    onGuestInfoSubmitted (data) {
      this.guestInfo = data
    },

    clearBasket () {
      this.basket.items = []
    },

    confirmClearBasket () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Hapus semua order items?',
        acceptText: 'Ya',
        cancelText: 'Batal',
        accept: () => { this.clearBasket() }
      })
    },

    confirmCancel () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Reset order?',
        acceptText: 'Ya',
        cancelText: 'Batal',
        accept: () => {
          this.resetDataGuestInfoForm()
          this.resetProcessPayment()
          this.resetData()
        }
      })
    },

    resetDataGuestInfoForm () {
      this.$refs.guestInfoForm.resetData()
    },

    resetProcessPayment () {
      this.$refs.processPayment.resetData()
    },

    onPriceChanged (newPrice) {
      this.modalChangePrice.item.harga = newPrice
      this.addToBasket(this.modalChangePrice.item)
    },

    showModalChangePrice (item) {
      this.modalChangePrice.item = item
      this.modalChangePrice.active = true
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['data', 'itemSales'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    scrollToElement (uuid) {
      const el = this.$refs[uuid][0]
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>
